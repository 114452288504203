<template>

<div>

        <!--half-half 2 column section demo-->
        <div class="fullwidth h-auto">
            <div class="row h-auto p-0">
                <div class="col-lg-5 col-md-5 col-sm-4 col-xs-6 h-auto hidden-xs">
                    <div class="trading-account-page-img">

                    </div>

                </div>
                <div class="col-lg-7 col-md-7 col-sm-8 col-xs-12 h-auto col-xs-offset-0" style="position: relative">
                        <router-view></router-view>
                </div>
            </div>
        </div>

        <!-- Start Footer Area -->
<!--        <footer class="footer-1">-->
<!--            <div class="footer-area-bottom">-->
<!--                <div class="container">-->
<!--                    <div class="row">-->
<!--                        <div class="col-md-6 col-sm-6 col-xs-12">-->
<!--                            <div class="copyright">-->
<!--                                <p>-->
<!--                                    Copyright ©2021-->
<!--                                    <a href="#">TransCrypt</a> All Rights Reserved-->
<!--                                </p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </footer>-->
        <!-- End Footer Area -->

<!--        <div>-->
<!--            Top page account layout-->
<!--        </div>-->
<!--       -->
<!--        <div>-->
<!--            Bottom page account layout-->
<!--        </div>-->
</div>
</template>
<script>
    export default {
        name : 'trading-credential-layout',
        data(){
            return {

            }
        },
        methods: {

        }
    }
</script>

<style scoped>

</style>
